<template>
  <DashboardBackgroundDecoration />
  <div class="grow flex flex-col">
    <div class="navbar bg-base-100/90 backdrop-blur sticky top-0 z-50 shadow">
      <div
        class="relative container mx-auto px-4 flex justify-between items-center gap-2">
        <h1 class="flex gap-2 items-center">
          <img src="/img/brand.png" class="max-h-8" />
          <span class="text-lg font-medium hidden md:inline"
            >Instabooth Dashboard</span
          >
        </h1>
        <NuxtLink
          v-if="tenantName"
          :to="localeRoute('events')"
          class="absolute left-1/2 -translate-x-1/2 text-nowrap overflow-hidden text-ellipsis max-w-[210px] hidden sm:block md:max-w-none text-primary px-4 py-1 rounded-box shadow bg-white hover:ring-2">
          {{ tenantName }}
        </NuxtLink>
        <UserMenu />
      </div>
    </div>
    <div class="container mx-auto p-6 pt-8">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
  const { locale, setLocale } = useI18n();
  const sessionStore = useSessionStore();
  const tenantName = ref<string>();
  const localeRoute = useLocaleRoute();

  sessionStore.$subscribe((m, s) => {
    tenantName.value = s.tenant?.data.name;
  });
</script>

<style>
  body {
    padding-top: env(safe-area-inset-top);
  }
</style>
